import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import Img from "gatsby-image/withIEPolyfill"

const Customers = () => {
  const data = useStaticQuery(graphql`
      query {
          customers: allDirectusCustomer {
              nodes {
                  logo {
                      localFile {
                          childImageSharp {
                              fluid(maxHeight: 60) {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const setActive = (value) => {
    setActiveItemIndex(value);
  };

  setTimeout(() => {
    setActive(activeItemIndex + 1);
  }, 2000);

  return (
    <ItemsCarousel
      infiniteLoop
      gutter={24}
      activePosition={'center'}
      numberOfCards={4}
      slidesToScroll={1}
      activeItemIndex={activeItemIndex}
      classes={{ itemsInnerWrapper: 'align-items-center'}}
    >
      { data.customers.nodes.map((customer, key) => (
        <Img key={key} fluid={customer.logo.localFile.childImageSharp.fluid} objectFit="fill"  />
      ))}
    </ItemsCarousel>
  )
}

export default Customers
