import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/it/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"
import ReactTimeAgo from "react-time-ago"
import JavascriptTimeAgo from "javascript-time-ago"
import it from "javascript-time-ago/locale/it"
import Customers from "../../components/customers"

export const query = graphql`
    query($slug: String!) {
        case: directusCaseHistory(slug: {eq: $slug}) {
            title
            subtitle
            intro
            content
            slug
            outro
            date
            image {
                localFile {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

JavascriptTimeAgo.locale(it)

const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/');

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={ `Case History: ${data.case.title}` } description={ data.case.intro } lang={"it"} url={`/case-history/${data.case.slug}`} />
      <Container fluid>
        <Row className={"site-content align-items-center case-history-page"}>
          <Col md={10} lg={5} className={"offset-md-1 order-2 order-lg-1"}>
            <Row>
              <Col>
                <h1>{ data.case.title }</h1>
                <h3>{ data.case.subtitle } <small className={"text-muted ml-3"}><ReactTimeAgo date={ fixDateForAllBrowsers(data.case.date) } locale={"it"}/></small></h3>
                <div className={"wysiwyg intro mt-5"}>
                  <h4>Premesse e obiettivi</h4>
                  <div dangerouslySetInnerHTML={{ __html:  data.case.intro }}/>
                </div>
                <div className={"wysiwyg content"}>
                  <h4>Attività svolte</h4>
                  <div dangerouslySetInnerHTML={{ __html:  data.case.content }}/>
                </div>
                <div className={"wysiwyg outro"}>
                  <h4>Risultati</h4>
                  <div dangerouslySetInnerHTML={{ __html:  data.case.outro }}/>
                </div>
              </Col>
              <Col md={12} className={"case-history-customers"}>
                <h4 className={"mb-5 text-center"}>I nostri clienti</h4>
                <Customers/>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={5} className={"offset-lg-1 p-0 order-1 order-lg-2 mb-5"}>
            <Img fluid={data.case.image.localFile.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
